import { createAsyncThunk } from "@reduxjs/toolkit";

import { getChatHistoryListByDate, getFirstQuestionList, getUniqueQuestionList } from "api/history";

export const getFirstQuestionListThunk = createAsyncThunk(
  "/getFirstQuestionList",
  async (data) => {
    try {
      const response = await getFirstQuestionList(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getChatHistoryListByDateThunk = createAsyncThunk(
  "/getChatHistoryListByDate",
  async (data) => {
    try {
      const response = await getChatHistoryListByDate(data);
      return {
        date: data.date,
        result: response
      };
    } catch (error) {
      return error;
    }
  }
);

export const getUniqueQuestionListThunk = createAsyncThunk(
  "getUniqueQuestionList",
  async (data) => {
    try {
      const response = await getUniqueQuestionList(data);
      return response;
    } catch (error) {
      return error;
    }
  }
)