import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedGraph, setShareCollabrationVisibility } from 'slices/collabration/reducer';

// Images
import ShareIcon from "images/share-icon.svg";


const ShareToCollabrationButton = ({ data = null }) => {

    const dispatch = useDispatch();

    const openShareGraphModal = () => {
        dispatch(setSelectedGraph(data));
        dispatch(setShareCollabrationVisibility(true));
    }

    if (!data) {
        return null
    }

    return (
        <img
            onClick={openShareGraphModal}
            className={`h-6 w-6 cursor-pointer`}
            src={ShareIcon}
            alt="Add to Colobration"
        />
    );
};

export default ShareToCollabrationButton;
