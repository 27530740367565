import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const Axios = axios.create({ baseURL });

Axios.interceptors.request.use(
  (request) => request,
  (err) => Promise.reject(err)
);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Axios;
