import HighchartsComponentAlt from "components/HighchartsComponentAlt";
import MoreButton from "components/Dashboard/MoreButton";
import { useDispatch } from "react-redux";
import { setAddAlertVisibility, setDeleteGraphVisibility, setGraphModalVisibility, setSelectedGraph } from "slices/dashboard/reducer";
import { moveChartToDashboardThunk } from "slices/dashboard/thunk";
import { notifyError, notifyInfo } from "utils/customToasts";
import { getJSONObject } from "utils/helper";

// Images
import FullScreenIcon from "images/full-screen-icon.svg";

// const dashboardData = {
//     query_id: 1,
//     summary: "<h2>Summary:</h2><ul><li>Overall, there is a general increasing trend in sales from January to May, followed by a slight decrease until July, and then an increase again until October. The sales peak in October and then decrease slightly in November and December.</li><li>The sales in March, May, and October are the highest throughout the year, indicating potential seasonal trends or factors influencing higher sales during these months.</li><li>There are no significant outliers or extreme fluctuations in sales each month, suggesting a relatively stable performance throughout the year.</li></ul><h2>",
//     actions: "<ol><li>Explore the reasons behind the high sales in March, May, and October to capitalize on potential seasonal trends or factors driving these increases.</li><li>Analyze the factors contributing to the slight decrease in sales from June to July and consider strategies to maintain or increase sales during this period.</li><li>Monitor sales performance closely in November and December to understand the factors leading to a slight decrease in sales compared to the previous months and implement targeted marketing or promotional activities to boost year-end sales.</li></ol>",
//     table: [
//         { Month: 1, TotalSales: 3418600.7 },
//         { Month: 2, TotalSales: 2574716.6 },
//         { Month: 3, TotalSales: 3792793.55 },
//         { Month: 4, TotalSales: 2995417.8 },
//         { Month: 5, TotalSales: 4329383.65 },
//         { Month: 6, TotalSales: 2971687.4 },
//         { Month: 7, TotalSales: 2862597.9 },
//         { Month: 8, TotalSales: 3314981.15 },
//         { Month: 9, TotalSales: 2784485.95 },
//         { Month: 10, TotalSales: 3938214.65 },
//         { Month: 11, TotalSales: 3589431.65 },
//         { Month: 12, TotalSales: 3314905.7 }
//     ],
//     sql: "WITH MonthlySales AS ( SELECT MONTH(OrderDate) AS Month, SUM(UnitPrice * Quantity * (1 - Discount)) AS TotalSales FROM Orders JOIN Order_Details ON Orders.OrderID = Order_Details.OrderID WHERE YEAR(OrderDate) = 2017 GROUP BY Month ) SELECT Month, TotalSales FROM MonthlySales;",
//     graph_type: "line",
//     graph_head: "Sales on each month of 2017",
//     highchart_code: {
//         Title: "Total Sales on Each Month of 2017",
//         x_axis: "Month",
//         y_axis: "TotalSales",
//         plot_type: "bar"
//     },
//     related_questions: [
//         "What were the total sales for each month of the year 2017?",
//         "Identify any seasonal trends in sales for each product category from 2013 to 2022.",
//         "Which region contributed the most to sales in the year 2022?"
//     ],
//     audio_path: "https://storymetrics-uploads.s3.amazonaws.com/test-file.mp3",
//     summary_text: "Summary:Overall, there is a general increasing trend in sales from January to May, followed by a slight decrease until July, and then an increase again until October. The sales peak in October and then decrease slightly in November and December.The sales in March, May, and October are the highest throughout the year, indicating potential seasonal trends or factors influencing higher sales during these months.There are no significant outliers or extreme fluctuations in sales each month, suggesting a relatively stable performance throughout the year.",
//     actions_text: "Explore the reasons behind the high sales in March, May, and October to capitalize on potential seasonal trends or factors driving these increases.Analyze the factors contributing to the slight decrease in sales from June to July and consider strategies to maintain or increase sales during this period.Monitor sales performance closely in November and December to understand the factors leading to a slight decrease in sales compared to the previous months and implement targeted marketing or promotional activities to boost year-end sales."
// };

const DashBoardGraph = ({ chartData, selectedDashboard }) => {

    const tableData = getJSONObject(chartData.df_data)
    const dataToPlot = getJSONObject(chartData.highchart_code);

    const graphCreatedAt = new Date(chartData.created_at);

    const dashboardData = {
        ...chartData,
        table: tableData,
        highchart_code: dataToPlot,
        createdAtDate: graphCreatedAt.toLocaleDateString(),
        createdAtTime: `${graphCreatedAt.getHours()}:${graphCreatedAt.getMinutes()}`,
    }

    const dispatch = useDispatch();

    const handleCreateAlert = () => {
        dispatch(setSelectedGraph(dashboardData));
        dispatch(setAddAlertVisibility(true));
    }

    const handleDeleteGraph = () => {
        dispatch(setSelectedGraph(dashboardData));
        dispatch(setDeleteGraphVisibility(true));
    }

    const handleMoveGraph = async (data) => {
        console.log("Selected graph data => ", data);
        const payload = {
            chartid: dashboardData?.chartid || null,
            old_dashid: dashboardData?.dashboard_id || null,
            new_dashid: data?.id || null,
        }
        const dispatchResponse = await dispatch(moveChartToDashboardThunk(payload));
        console.log(dispatchResponse);
        if (dispatchResponse?.type?.endsWith("/fulfilled")) {
            notifyInfo(`Chart moved in ${data.name}`);
        }

        if (dispatchResponse?.type?.endsWith("/rejected")) {
            notifyError(`Cannot move chart`);
        }
    }

    const handleGraphMaximize = () => {
        dispatch(setSelectedGraph({
            ...dashboardData,
            dashboard_name: selectedDashboard,
        }));
        dispatch(setGraphModalVisibility(true));
    }

    return (
        <div className="flex flex-col gap-2 p-4 border border-[#BEEF9E66] rounded-lg ">
            <HighchartsComponentAlt
                chartTypeOuter={dashboardData.graph_type}
                chartTitle={dashboardData.highchart_code.Title}
                data={dashboardData.table}
                dataToPlot={dashboardData.highchart_code}
            />

            <hr className="border-[#BEEF9E66]" />

            <div className={`flex justify-between items-center `}>
                <div>
                    <h2 className="font-medium text-sm">{selectedDashboard}</h2>
                    <div className="flex gap-2 items-center text-xs text-[#DADCDE]">
                        <span>
                            Created on {dashboardData?.createdAtDate} at {dashboardData?.createdAtTime}
                        </span>

                        <div className="h-5 max-h-full w-[1px] bg-[#00264E]"></div>

                        {
                            dashboardData?.alert_id && (
                                <span className="text-[#BEEF9E99]">
                                    Alert Created
                                </span>
                            )
                        }

                    </div>

                </div>

                <div className="flex gap-4 items-center">
                    <MoreButton
                        hasAlert={dashboardData?.alert_id}
                        onCreateAlert={handleCreateAlert}
                        onDeleteGraph={handleDeleteGraph}
                        onMoveGraph={handleMoveGraph}
                    />

                    <button onClick={handleGraphMaximize} className={`p-1 border border-primary-light rounded-lg`}>
                        <img className={`h-6 w-6`} src={FullScreenIcon} alt="Full-Screen" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DashBoardGraph;