import { getFirstQuestionListThunk, getChatHistoryListByDateThunk } from "./thunk";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    errors: {}
}

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(getFirstQuestionListThunk.fulfilled, (state, action) => {
        state.history = action.payload;
      });
      builder.addCase(getFirstQuestionListThunk.rejected, (state, action) => {
        state.errors.history = action.payload || null;
      });

      builder.addCase(getChatHistoryListByDateThunk.fulfilled, (state, action) => {
        state[action.payload.date] = action.payload?.result?.reverse() || [];
      });
      builder.addCase(getChatHistoryListByDateThunk.rejected, (state, action) => {
        state.errors[action.date] = action.payload || null;
      });
    },
  });
  
  export default chatSlice.reducer;