import React from 'react';

// Images
import MenuIcon from 'images/menu-icon.svg';
import { Menu, MenuItem, SubMenu } from '@szhsin/react-menu';
import ArrowRight from "images/arrow-right-icon.svg";
import AddSquareIcon from 'images/add-square-green-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setAddDashboardVisibility } from 'slices/dashboard/reducer';

const MoreButton = ({
    hasAlert = false,
    onMoveGraph,
    onDeleteGraph,
    onCreateAlert,
}) => {

    const dispatch = useDispatch();
    const { dashboards } = useSelector(state => state.DashboardReducer);

    const handleCreateAlert = () => {
        if (onCreateAlert) {
            onCreateAlert();
        }
    }

    const handleMoveGraph = (graphId) => {
        if (onMoveGraph) {
            onMoveGraph(graphId);
        }
    }

    const handleDeleteGraph = (graphId) => {
        if (onDeleteGraph) {
            onDeleteGraph(graphId);
        }
    }

    const createNewDashboard = () => {
        dispatch(setAddDashboardVisibility(true));
    }

    return (
        <Menu
            menuClassName={`box-border z-10 min-w-[9rem] bg-primary py-6 px-4 rounded shadow-primary-1`}
            menuButton={
                <button className={`p-1 border border-primary-light rounded-lg`}>
                    <img className={`h-6 w-6`} src={MenuIcon} alt="Menu" />
                </button>
            }
        >
            <SubMenu
                className={`cursor-pointer`}
                menuClassName={`box-border min-w-[13rem] z-10 opacity-1 bg-primary py-6 px-4 rounded shadow-primary-1`}
                label={
                    <div className={`flex justify-between items-center`}>
                        <span>Move to</span>
                        <img className="h-3 w-3" src={ArrowRight} alt=">" title="Move to >" />
                    </div>
                }
            >
                <MenuItem onClick={createNewDashboard} className={`cursor-pointer`}>
                    <div
                        className="flex items-center gap-2"
                    >
                        <span className="text-secondary-1 text-nowrap">Create new dashboard</span>
                        <img src={AddSquareIcon} alt="Add Dashboard" />
                    </div>
                </MenuItem>
                {
                    dashboards.map((dashboard) => (
                        <MenuItem onClick={() => handleMoveGraph(dashboard)} key={dashboard?.id} className={`cursor-pointer`}>
                            {dashboard?.name || "---"}
                        </MenuItem>
                    ))
                }
            </SubMenu>
            <MenuItem onClick={handleCreateAlert} className={`cursor-pointer`}>{hasAlert ? "Edit" : "Create"} alert</MenuItem>
            <MenuItem onClick={handleDeleteGraph} className={`cursor-pointer`}>Delete</MenuItem>
        </Menu>
    );
};

export default MoreButton;
