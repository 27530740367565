import { createSlice } from "@reduxjs/toolkit";
import {
    getDashboardsThunk,
    createDashboardThunk,
    addChartToDashboardThunk,
    getDashboardChartsThunk,
    deleteChartFromDashboardThunk,
    moveChartToDashboardThunk
} from "slices/dashboard/thunk";

const initialState = {
    fetchDashboards: true,
    fetchDashboardCharts: true,
    selectedDashboard: {
        label: "All Dashboards",
        value: null
    },
    selectedGraph: null,
    addDashboardVisibility: false,
    addAlertVisibility: false,
    deleteGraphVisibility: false,
    graphModalVisibility: false,
    dashboards: [],
    dashboardCharts: [],
    errors: {}
}

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        setDashboards: (state, action) => {
            const payload = action.payload;
            state.dashboards = payload
        },

        addDashboard: (state, action) => {
            const payload = action.payload;
            state.dashboards = [...state.dashboards, payload]
        },

        updateDashboard: (state, action) => {
            const payload = action.payload;
            state.dashboards = state.dashboards.map(dashboard => dashboard.dashboard_id === payload.dashboard_id ? payload : dashboard)
        },

        deleteDashboard: (state, action) => {
            const payload = action.payload;
            state.dashboards = state.dashboards.filter(dashboard => dashboard.dashboard_id !== payload.dashboard_id)
        },

        setSelectedDashboard: (state, action) => {
            const payload = action.payload;
            state.selectedDashboard = payload
        },

        setAddDashboardVisibility: (state, action) => {
            const payload = action.payload;
            state.addDashboardVisibility = payload
        },

        setAddAlertVisibility: (state, action) => {
            const payload = action.payload;
            state.addAlertVisibility = payload
        },

        setDeleteGraphVisibility: (state, action) => {
            const payload = action.payload;
            state.deleteGraphVisibility = payload
        },

        setGraphModalVisibility: (state, action) => {
            const payload = action.payload;
            state.graphModalVisibility = payload
        },

        setSelectedGraph: (state, action) => {
            const payload = action.payload;
            state.selectedGraph = payload
        },

        setFetchDashboards: (state, action) => {
            const payload = action.payload;
            state.fetchDashboards = payload
        },

        setFetchDashboardCharts: (state, action) => {
            const payload = action.payload;
            state.fetchDashboardCharts = payload
        }

    },
    extraReducers: (builder) => {
        // Add Dashboard
        builder.addCase(createDashboardThunk.fulfilled, (state, action) => {
            const dashboardId = action?.payload?.dashboard_id || null;
            const dashboardName = action?.meta?.arg?.dashboard_name || null;

            if (dashboardId && dashboardName) {
                const newDashboard = {
                    name: dashboardName,
                    id: dashboardId
                }
                state.dashboards = [...state.dashboards, newDashboard];
            }
        });
        builder.addCase(createDashboardThunk.rejected, (state, action) => {
            state.errors.dashboards = action.payload || null;
        });

        // Get Dashboards
        builder.addCase(getDashboardsThunk.fulfilled, (state, action) => {
            const dashboards = action?.payload?.dashboards.map(x => {
                x.id = x.dashboard_id;
                delete x.dashboard_id;
                return x;
            }) || [];
            state.dashboards = dashboards;
        });
        builder.addCase(getDashboardsThunk.rejected, (state, action) => {
            state.errors.dashboards = action.payload || null;
        });

        // Add new chart to dashboard
        builder.addCase(addChartToDashboardThunk.fulfilled, (state, action) => {

            const dashboard_id = action?.meta?.arg?.dashid || null;
            const chart_id = action?.payload?.chart_id || null;

            const newChartData = {
                dashboard_id,
                chart_id,
                alert_id: null,
                created_at: new Date(),
                updated_at: new Date(),
                actions: state.selectedGraph?.actions || "",
                highchart_code: JSON.stringify(state.selectedGraph?.graphDataToPlot),
                df_data: JSON.stringify(state.selectedGraph?.graph),
                question: null,
            }

            state.dashboardCharts = [newChartData, ...state.dashboardCharts];
        });
        builder.addCase(addChartToDashboardThunk.rejected, (state, action) => {
            state.errors.addChartToDashboard = action.payload || null;
        });

        // Get dashboard charts
        builder.addCase(getDashboardChartsThunk.fulfilled, (state, action) => {
            const charts = action?.payload?.charts || []
            state.dashboardCharts = charts
        });
        builder.addCase(getDashboardChartsThunk.rejected, (state, action) => {
            state.errors.getDashboardCharts = action.payload || null;
        });

        // Delete chart from dashboard
        builder.addCase(deleteChartFromDashboardThunk.fulfilled, (state, action) => {
            const chartId = action?.meta?.arg?.chartid;
            if (chartId) {
                state.dashboardCharts = state.dashboardCharts.filter(chart => chart.chartid !== chartId);
            }
        });
        builder.addCase(deleteChartFromDashboardThunk.rejected, (state, action) => {
            state.errors.deleteChartFromDashboard = action.payload || null;
        });

        // Move chart to dashboard
        builder.addCase(moveChartToDashboardThunk.fulfilled, (state, action) => {
            const chartId = action.meta.arg?.chartid || null;
            const oldDashId = action.meta.arg?.old_dashid || null;
            const newDashId = action.meta.arg?.new_dashid || null;

            // Return if new dashboard or chartId is null
            if (!chartId || !newDashId) {
                console.log("Invalid chart selected ");
                return;
            }

            const chartIndex = state.dashboardCharts.findIndex(chart => chart.chartid === chartId && chart.dashboard_id === oldDashId);

            // Return if there are no matching charts
            if (chartIndex === -1) {
                return console.log("Chart not found in old dashboard");
            }

            const updatedData = {
                ...state.dashboardCharts[chartIndex],
                dashboard_id: newDashId,
                updated_at: new Date().toString()
            };

            // Return a new state with the updated chart
            state.dashboardCharts = [
                ...state.dashboardCharts.slice(0, chartIndex),
                updatedData,
                ...state.dashboardCharts.slice(chartIndex + 1)
            ];
        });
        builder.addCase(moveChartToDashboardThunk.rejected, (state, action) => {
            state.errors.moveChartToDashboard = action.payload || null;
            console.log("Move chart Error", action);
        });
    }
});

export const {
    setDashboards,
    addDashboard,
    updateDashboard,
    deleteDashboard,
    setSelectedGraph,
    setSelectedDashboard,
    setAddAlertVisibility,
    setDeleteGraphVisibility,
    setAddDashboardVisibility,
    setGraphModalVisibility,
    setFetchDashboards,
    setFetchDashboardCharts
} = dashboardSlice.actions;

export default dashboardSlice.reducer;