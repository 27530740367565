import { toast } from "react-toastify"

const toastOptions = {
    className: "text-black",
    bodyClassName: "text-sm",
}

export const notifySuccess = (message = "Successful") => {
    toast.dismiss();
    toast.success(message, toastOptions);
}

export const notifyError = (message = "Failed") => {
    toast.dismiss();
    toast.error(message, toastOptions);
}

export const notifyWarning = (message = "Warning") => {
    toast.dismiss();
    toast.warning(message, toastOptions);
}

export const notifyInfo = (message = "Info") => {
    toast.dismiss();
    toast.info(message, toastOptions);
}