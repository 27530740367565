import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Heatmap from 'highcharts/modules/heatmap';
import HighchartsMore from 'highcharts/highcharts-more';

Heatmap(Highcharts);
HighchartsMore(Highcharts);

const transformPlotData = (data) => {
    const newData = {};
    for (const [key, value] of Object.entries(data)) {
        if (key && value) {
            // lowercase the key and remove any special symbol
            const optimizedKey = key?.toLowerCase()?.replace(/[^a-z0-9]/g, '');
            if (optimizedKey) {
                newData[optimizedKey] = value;
            }
        }
    }
    return newData;
}

const HighchartsComponentAlt = ({ chartTypeOuter, chartTitle, data, dataToPlot }) => {
    const chartRef = useRef(null);
    const [chartOptions, setChartOptions] = useState({});

    const dataToPlotOptimized = transformPlotData(dataToPlot);

    const chartHeading = dataToPlotOptimized?.title || chartTitle;
    const chartType =  dataToPlotOptimized?.plottype || chartTypeOuter;
    const xAxisKey = "xaxis";
    const yAxisKey = "yaxis";

    useEffect(() => {
        if (data && data.length > 0) {

            const xValues = data.map(x => x[dataToPlotOptimized[xAxisKey]]);

            const seriesData = [];
            for (const key of Object.keys(data[0])) {
                if (key === dataToPlotOptimized[xAxisKey]) {
                    continue;
                }

                const series = data.map(x => x[key]);
                seriesData.push({
                    name: key,
                    data: series,
                })
            }

            if (seriesData.length === 0) {
                console.error('Series data is empty or undefined.');
                return;
            }

            // Determine if yAxis is an array (multiple axes) or an object (single axis)
            const yAxisConfig = Array.isArray(dataToPlotOptimized?.[yAxisKey])
                ? dataToPlotOptimized?.[yAxisKey].map(axis => ({
                    title: {
                        text: axis || "Values",
                        style: { color: '#ffffff', fontFamily: 'var(--font-montserrat)' }
                    },
                    labels: {
                        style: { color: '#ffffff', fontFamily: 'var(--font-montserrat)' },
                        formatter: function () {
                            return formatNumber(this.value);
                        }
                    },
                    gridLineWidth: 0, // Hide y-axis grid lines
                }))
                : {
                    title: {
                        text: dataToPlotOptimized?.[yAxisKey] || "Values",
                        style: { color: '#ffffff', fontFamily: 'var(--font-montserrat)' }
                    },
                    labels: {
                        style: { color: '#ffffff', fontFamily: 'var(--font-montserrat)' },
                        formatter: function () {
                            return formatNumber(this.value);
                        }
                    },
                    gridLineWidth: 0, // Hide y-axis grid lines
                };

            const options = {
                chart: {
                    backgroundColor: 'transparent',
                    plotBackgroundColor: 'transparent',
                    type: chartType === 'pie' ? 'pie' : chartType,
                    height: 350,
                },
                title: {
                    text: chartHeading,
                    style: { color: "#ffffff", fontFamily: 'var(--font-montserrat)' }
                },
                xAxis: {
                    categories: xValues,
                    title: {
                        text: dataToPlotOptimized[xAxisKey],
                        style: { color: '#ffffff', fontFamily: 'var(--font-montserrat)' }
                    },
                    labels: {
                        style: { color: '#ffffff', fontFamily: 'var(--font-montserrat)' }
                    },
                    gridLineWidth: 0, // Hide x-axis grid lines
                },
                yAxis: yAxisConfig,
                series: seriesData,
                credits: {
                    enabled: false
                },
                legend: {
                    itemStyle: {
                        color: '#fff',
                        fontFamily: 'var(--font-montserrat)'
                    }
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                            color: '#fff',
                            style: { fontFamily: 'var(--font-montserrat)' },
                            formatter: function () {
                                return formatNumber(this.y);
                            }
                        },
                        connectNulls: true, // Connect points if null values are present
                        marker: {
                            enabled: true
                        }
                    }
                },
                tooltip: {
                    style: { fontFamily: 'var(--font-montserrat)' },
                    formatter: function () {
                        return `<b>${this.series.name}</b><br/>${this.key}: ${formatNumber(this.y)}`;
                    }
                }
            };

            setChartOptions(options);
        }
    }, [chartType, data, chartHeading, dataToPlot]);

    const formatNumber = (value) => {
        if (typeof value === 'number') {
            if (value >= 1e9) {
                return (value / 1e9).toFixed(1) + 'B';
            } else if (value >= 1e6) {
                return (value / 1e6).toFixed(1) + 'M';
            } else if (value >= 1e3) {
                return (value / 1e3).toFixed(1) + 'k';
            } else {
                return value.toFixed(2);
            }
        }
        return value; // Return as is if not a number
    };

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />;
};

export default HighchartsComponentAlt;