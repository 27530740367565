import Modal from "components/Modal";
import { useEffect, useState } from 'react';
import loadingGif from 'images/loader2.webp';
import { useDispatch, useSelector } from "react-redux";
import { setAddDashboardVisibility } from "slices/dashboard/reducer";
import { CreateDashboardSchema } from '../../validations/dashboard';
import { createDashboard } from "api/dashboard";
import {createDashboardThunk} from '../../slices/dashboard/thunk';

const CreateDashboardModal = () => {

    const dispatch = useDispatch();

    const [dashboardName, setDashboardName] = useState("");
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({ errorCount: 0 });

    const { addDashboardVisibility } = useSelector(state => state.DashboardReducer);

    const verifyPayload = async (payload) => {
        try {
            return CreateDashboardSchema.validate(payload, { abortEarly: false });
        } catch (err) {
            console.log("Dashboard Validation Error: ", err);
            throw new Error(err)
        }
    }

    const handleSaveDashboard = async () => {
        setLoading(true);
        try {
            const payload = {
                user_id: 1, // This is static user ID.
                dashboard_name: dashboardName,
            }

            const verifiedPayload = await verifyPayload(payload);
            // Create Dashboard Request
            const response = dispatch(createDashboardThunk(verifiedPayload));
            // Reset Modal states
            resetModalStates();
        } catch (err) {
            let errorCount = 0
            const errObj = {};
            err.inner.forEach(error => {
                errObj[error.path] = error.message;
                errorCount += 1
            })
            setFormErrors({ ...errObj, errorCount });
        } finally {
            setLoading(false);
        }
    }

    const resetModalStates = () => {
        setLoading(false);
        setFormErrors({ errorCount: 0 })
        dispatch(setAddDashboardVisibility(false));
    }

    useEffect(() => {
        // flush the states
        return () => {
            resetModalStates();
        }
    }, []);

    return (
        <Modal
            position="top-right"
            className="w-[40rem] px-8 py-4 bg-primary-3 text-white rounded-lg text-left"
            open={addDashboardVisibility}
        >
            <div>
                <label
                    htmlFor="email"
                    className={`block text-sm `}
                >
                    Dashboard Name
                </label>
                <input
                    type="text"
                    id="create-new-dashboard"
                    value={dashboardName}
                    onChange={(e) => setDashboardName(e.target.value)}
                    className={`w-full mt-2 p-3 rounded bg-[#0D1B2A] border ${formErrors?.dashboard ? "border-red-500" : "border-[#382F5C]"}`}
                    placeholder="Enter dashboard name"
                />
                <span className="text-red-500">{formErrors?.dashboard}</span>
            </div>

            <div className="flex justify-end mt-6 gap-4">
                <button disabled={loading} className="py-2 text-gray-300 px-4 border border-gray-200 rounded" onClick={resetModalStates}>Cancel</button>
                <button
                    disabled={loading}
                    className="py-2 bg-primary-1 px-4 rounded text-white flex gap-2 items-center disabled:bg-gray-600 disabled:text-gray-300"
                    onClick={handleSaveDashboard}
                >
                    {
                        loading && (
                            <img src={loadingGif} alt="loading" className={`h-4 w-4`} />
                        )
                    }
                    <span>
                        {loading ? "Saving" : "Save"}
                    </span>
                </button>
            </div>
        </Modal>
    );
}

export default CreateDashboardModal