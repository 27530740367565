export const createBotMessage = (message) => {
  const botMessage = {
    query_id: message.query_id,
    sender: 'bot',
    content: message?.summary || message?.answer,
    copyContent: `summary - ${message?.summary_actions?.summary_text} actions - ${message?.summary_actions?.actions_text}`,
    actions: message?.actions,
    sqlQuery: message?.sql_query || '',
    firstMessage: 'no',
  };

  if (message?.df_data?.length > 1 && message?.highchart_code?.length > 1) {
    const chartObj = {
      graphTitle: message?.graph_head,
      graphType: message?.graph_type,
      graph: JSON.parse(message?.df_data),
      graphDataToPlot: JSON.parse(message?.highchart_code),
    };

    const requiredFields = ["Title", "plot_type"];
    const hasXAxis = chartObj.graphDataToPlot?.["x_axis"] || chartObj.graphDataToPlot?.["x-axis"];
    const hasYAxis = chartObj.graphDataToPlot?.["y_axis"] || chartObj.graphDataToPlot?.["y-axis"];
    const hasRequiredFields = requiredFields.every(field => chartObj.graphDataToPlot.hasOwnProperty(field))

    if (hasXAxis && hasYAxis && hasRequiredFields) {
      Object.assign(botMessage, chartObj);
    } else {
      console.log("Not Enough data for chart", chartObj.graphDataToPlot)
    }
  }

  return botMessage;
};